import { ProductDetailParams } from '@api/catalog/product/types';
import { CommonSearchParams } from '@api/common/types';
import { APIClient } from '@api/index';

import { QUERY_KEY_AUTH_OFFERS, QUERY_KEY_OFFERS } from '../products';
import { ProductFilter } from '../types';

const API_URL = 'catalog/products';
const API_URL_AUTH = 'catalog/auth-products';

const QUERY_KEY_PRODUCT = 'product';
const QUERY_KEY_AUTH_PRODUCT = 'auth-product';

export const getProducts = (apiClient: APIClient) => (data: CommonSearchParams<Partial<ProductFilter>>) => ({
    key: [QUERY_KEY_PRODUCT, data],
    fetch: () =>
        apiClient.post(`${API_URL}:search`, {
            data,
        }),
});

export const getProductsAuth = (apiClient: APIClient) => (data: CommonSearchParams<Partial<ProductFilter>>) => ({
    key: [QUERY_KEY_AUTH_PRODUCT, data],
    fetch: () =>
        apiClient.post(`${API_URL_AUTH}:search`, {
            data,
        }),
});

export const getProductDetail =
    (apiClient: APIClient) =>
    ({ id, ...rest }: ProductDetailParams) => ({
        key: [`${QUERY_KEY_PRODUCT}-${id}`],
        fetch: () => apiClient.get(`${API_URL}/${id}`, { params: { ...rest } }),
    });

export const getProductDetailAuth =
    (apiClient: APIClient) =>
    ({ id, ...rest }: ProductDetailParams) => ({
        key: [`${QUERY_KEY_AUTH_PRODUCT}-${id}`],
        fetch: () => apiClient.get(`${API_URL}/${id}`, { params: { ...rest } }),
    });

export const getProductFilters = (apiClient: APIClient) => (data: CommonSearchParams<Partial<ProductFilter>>) => ({
    key: [QUERY_KEY_PRODUCT, data],
    fetch: () =>
        apiClient.post(`${API_URL}:facets`, {
            data,
        }),
});

const API_OFFER_URL = 'catalog/offers';
const API_OFFER_URL_AUTH = 'catalog/auth-offers';

const QUERY_KEY_OFFER = 'offer';
const QUERY_KEY_AUTH_OFFER = 'auth-offer';

export const getOffers = (apiClient: APIClient) => (data: CommonSearchParams<Partial<ProductFilter>>) => ({
    key: [QUERY_KEY_OFFERS, data],
    fetch: () =>
        apiClient.post(`${API_OFFER_URL}:search`, {
            data,
        }),
});

export const getOfferAuth = (apiClient: APIClient) => (data: CommonSearchParams<Partial<ProductFilter>>) => ({
    key: [QUERY_KEY_AUTH_OFFERS, data],
    fetch: () =>
        apiClient.post(`${API_OFFER_URL_AUTH}:search`, {
            data,
        }),
});

export const getOfferDetail =
    (apiClient: APIClient) =>
    ({ id, ...rest }: ProductDetailParams) => ({
        key: [`${QUERY_KEY_OFFER}-${id}`],
        fetch: () => apiClient.get(`${API_OFFER_URL}/${id}`, { params: { ...rest } }),
    });

export const getOfferDetailAuth =
    (apiClient: APIClient) =>
    ({ id, ...rest }: ProductDetailParams) => ({
        key: [`${QUERY_KEY_AUTH_OFFER}-${id}`],
        fetch: () => apiClient.get(`${API_OFFER_URL_AUTH}/${id}`, { params: { ...rest } }),
    });

export const getOfferFilters = (apiClient: APIClient) => (data: CommonSearchParams<Partial<ProductFilter>>) => ({
    key: [QUERY_KEY_OFFERS, data],
    fetch: () =>
        apiClient.post(`${API_OFFER_URL}:facets`, {
            data,
        }),
});
