import { CSSObject } from '@emotion/core';
import { FieldMetaProps } from 'formik';
import { useMemo } from 'react';

import { scale, typography, useTheme } from '@scripts/gds';

export const useFieldCSS = (meta?: FieldMetaProps<any>, theme?: string) => {
    const { components } = useTheme();
    const inputTheme = theme && components?.Input?.[theme] ? components?.Input?.[theme] : components?.Input?.default;

    const isError = meta?.touched && meta?.error;

    const basicFieldCSS: CSSObject = useMemo<CSSObject>(() => {
        const defaultSizePadding = scale(1, true);
        const { paddingY, paddingLeft, paddingRight } = inputTheme ?? {};
        const paddingTop = inputTheme?.paddingTop ? inputTheme?.paddingTop : paddingY || undefined;
        const paddingBottom = inputTheme?.paddingBottom ? inputTheme?.paddingBottom : paddingY || undefined;

        return {
            width: '100%',
            minHeight: inputTheme?.height,
            paddingTop,
            paddingBottom,
            paddingLeft,
            paddingRight,
            padding: inputTheme?.padding ?? defaultSizePadding,
            color: inputTheme?.color,
            border: inputTheme?.border,
            background: inputTheme?.bg,

            ...((inputTheme?.borderBottom || inputTheme?.borderColor || isError) && {
                borderBottom: `1px solid ${
                    isError ? inputTheme?.errorBorderColor : inputTheme?.borderBottom || inputTheme?.borderColor
                }`,
            }),
            ...(isError &&
                inputTheme?.errorBorderColor && {
                    borderColor: inputTheme?.errorBorderColor,
                }),
            borderRadius: inputTheme?.borderRadius || 0,
            ...(inputTheme?.typography && typography(inputTheme?.typography)),
            ':focus': { outline: 'none', borderColor: inputTheme?.focusBorderColor },
            ':disabled': {
                background: inputTheme?.disabledBg,
                border: `1px solid ${inputTheme?.disabledBorderColor}`,
                color: inputTheme?.placeholderColor,
                cursor: 'not-allowed',
            },
            '::placeholder': { color: inputTheme?.placeholderColor },
        };
    }, [
        inputTheme?.height,
        inputTheme?.padding,
        inputTheme?.color,
        inputTheme?.errorBorderColor,
        inputTheme?.borderColor,
        inputTheme?.borderRadius,
        inputTheme?.bg,
        inputTheme?.typography,
        inputTheme?.focusBorderColor,
        inputTheme?.disabledBg,
        inputTheme?.disabledBorderColor,
        inputTheme?.placeholderColor,
        isError,
    ]);

    const inputWrapperCSS: CSSObject = {
        position: 'relative',
        fill: inputTheme?.iconColor,
    };

    return { basicFieldCSS, inputWrapperCSS };
};
