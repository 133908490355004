import { useCallback, useMemo } from 'react';

import { useBasketAddProduct, useGetBasket } from '@api/basket';
import { ProductDetail } from '@api/catalog';
import { getProductDetail } from '@api/catalog/product/api';
import { CommonResponse } from '@api/common/types';
import { useAuthApiClient } from '@api/hooks/useAuthApiClient';

import { useCartContext } from '@context/cart';

import { fromKopecksToRouble, getItemForMetrics, metricsPushEvent } from '@scripts/helpers';

import { QueryClient } from 'react-query';
import { useGetUserData } from './useGetUserData';

export const useAddToBasketHandler = () => {
    const { user: userToken } = useGetUserData();

    const apiClient = useAuthApiClient();

    const { basketProducts, setBasketProducts } = useCartContext();

    const { mutateAsync: addProduct } = useBasketAddProduct();

    const { data: apiBasketData } = useGetBasket(Boolean(userToken));
    const basketData = useMemo(() => apiBasketData?.data || [], [apiBasketData]);

    return useCallback(
        async (
            id: number,
            product?: ProductDetail,
            setIsLoadingBasket?: (val: boolean) => void,
            quantity: number = 1
        ) => {
            const currentBasketItem = userToken
                ? basketData.find(item => item.id === id)
                : basketProducts.find(item => item.offer_id === id);

            const quantityProduct = () => {
                if (!product) return 1;

                switch (true) {
                    case fromKopecksToRouble(product?.multiple) < quantity:
                        return quantity;
                    case fromKopecksToRouble(product?.multiple) > quantity:
                        return fromKopecksToRouble(product?.multiple);
                    default:
                        return 1;
                }
            };

            const queryClient = new QueryClient();

            const { key: baseProductKey, fetch: baseProductFetch } = getProductDetail(apiClient)({
                id,
                include: 'brand,images,category,files,seller,attributes',
            });

            let apiProduct: CommonResponse<ProductDetail> | null = null;

            if (!product?.id) apiProduct = await queryClient.fetchQuery(baseProductKey, baseProductFetch);

            if (userToken) {
                if (setIsLoadingBasket) setIsLoadingBasket(true);

                await addProduct([{ offer_id: id, qty: quantityProduct() }]).then(() => {
                    if (setIsLoadingBasket) setIsLoadingBasket(false);
                });
            } else {
                const newProducts = [...basketProducts];

                if (currentBasketItem) {
                    setBasketProducts(
                        newProducts?.map(item =>
                            item.offer_id === id
                                ? {
                                      ...item,
                                      qty: quantityProduct(),
                                  }
                                : item
                        )
                    );

                    return;
                }

                if (product)
                    newProducts.push({
                        ...product,
                        offer_id: id,
                        qty: quantityProduct(),
                    });

                if (apiProduct?.data) newProducts.push({ ...apiProduct?.data, offer_id: id, qty: quantityProduct() });
                setBasketProducts([...newProducts]);
            }

            if (product || apiProduct?.data)
                metricsPushEvent({
                    event: 'add_to_cart',
                    ecommerce: {
                        items: [
                            {
                                ...getItemForMetrics(product || apiProduct?.data),
                                quantity: currentBasketItem ? currentBasketItem.quantity + 1 : 1,
                            },
                        ],
                    },
                });
        },
        [basketData, basketProducts]
    );
};
