export const getPagination = (limit: number = 10, page: number = 1) => ({
    limit,
    type: 'offset' as const,
    offset: (page - 1) * limit,
});

export const getDefaultProps = ({ revalidate = 60, ...props }) => ({
    revalidate,
    ...props,
});
