import PoliticsBlock from '@components/PoliticsBlock';
import SocialMediaBlock from '@components/SocialMediaBlock';

import { Layout, Section, colors, scale } from '@scripts/gds';
import { useMedia } from '@scripts/hooks';

export default function FooterCopyRights() {
    const { mdMin, md, sm } = useMedia();

    return (
        <Section>
            <Layout
                cols={{ xxxl: 24, sm: 7 }}
                css={{
                    padding: `${scale(4)}px 0`,
                    whiteSpace: 'nowrap',
                    [md]: {
                        paddingTop: 0,
                    },
                }}
                gap={{ lg: scale(3, true), sm: scale(4) }}
            >
                <Layout.Item
                    col={{ xxxl: 8, sm: 7 }}
                    css={{
                        display: 'flex',
                        gap: scale(2),
                        [sm]: { flexDirection: 'column' },
                        [mdMin]: { display: 'none' },
                    }}
                >
                    <PoliticsBlock />
                </Layout.Item>

                <Layout.Item col={{ xxxl: 8, sm: 7 }} align="center">
                    <SocialMediaBlock />
                </Layout.Item>

                <Layout.Item
                    col={{ xxxl: 16, sm: 7 }}
                    align="center"
                    css={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        color: colors.textSecondGrey,
                        [md]: { display: 'none' },
                    }}
                >
                    <div
                        css={{
                            display: 'flex',
                            gap: scale(5),
                            [sm]: { flexDirection: 'column' },
                        }}
                    >
                        <PoliticsBlock />
                    </div>
                </Layout.Item>
            </Layout>
        </Section>
    );
}
