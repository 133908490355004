import { CommonResponse, CommonSearchParams, FetchError } from '@api/common/types';
import { useBaseQuery } from '@api/hooks';
import { useAuthApiClient } from '@api/hooks/useAuthApiClient';

import { CategoryDetail, CategorySearchItem, CategoryTreeItem, ProductFilter, VirtualCategory } from '../types';
import { getCategories, getCategoriesTree, getCategoryDetail, getVirtualCategoryDetail } from './api';

export const useCategoriesTree = (enabled = true) => {
    const apiClient = useAuthApiClient();

    return useBaseQuery<null, CommonResponse<CategoryTreeItem[]>, FetchError>({
        data: null,
        getMethod: () => getCategoriesTree(apiClient),
        enabled,
    });
};

export const useCategories = (data: CommonSearchParams<Record<string, boolean>>) => {
    const apiClient = useAuthApiClient();

    return useBaseQuery<CommonSearchParams<Record<string, boolean>>, CommonResponse<CategorySearchItem[]>, FetchError>({
        data,
        getMethod: params => getCategories(apiClient)(params),
    });
};

export const useCategoryDetail = (
    data: {
        id?: number;
        code?: string;
        offers_filter?: Partial<ProductFilter>;
        include?: string[];
    },
    enabled = true
) => {
    const apiClient = useAuthApiClient();

    return useBaseQuery<{ id?: number; code?: string }, CommonResponse<CategoryDetail>, FetchError>({
        data,
        getMethod: params => getCategoryDetail(apiClient)(params),
        enabled,
    });
};

export const useVirtualCategoryDetail = (
    data: { filter: { id?: number; code?: string }; include?: string[] },
    enabled = true
) => {
    const apiClient = useAuthApiClient();

    return useBaseQuery<
        { filter: { id?: number; code?: string }; include?: string[] },
        CommonResponse<VirtualCategory>,
        FetchError
    >({
        data,
        getMethod: params => getVirtualCategoryDetail(apiClient)(params),
        enabled,
    });
};
