import { AppProps } from 'next/app';
import { ReactNode } from 'react';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { AuthProvider } from '@context/auth';
import { BodyScrollLockProvider } from '@context/bodyScrollLock';
import { CartProvider } from '@context/cart';
import { CompareAndFavoriteProvider } from '@context/compare';
import { ModalProvider } from '@context/modal';

import { STALE_TIME } from '@scripts/constants';
import { ThemeProvider, theme } from '@scripts/gds';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: STALE_TIME,
            retry: 0,
            refetchOnWindowFocus: process.env.NODE_ENV === 'production',
        },
    },
});

interface AppProvidersProps extends AppProps {
    children: ReactNode;
}

const AppProviders = ({ children, pageProps }: AppProvidersProps) => (
    <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
            <Hydrate state={pageProps.dehydratedState}>
                <BodyScrollLockProvider>
                    <ModalProvider>
                        <CartProvider>
                            <AuthProvider state={pageProps}>
                                <CompareAndFavoriteProvider>{children}</CompareAndFavoriteProvider>
                            </AuthProvider>
                        </CartProvider>
                    </ModalProvider>
                </BodyScrollLockProvider>
            </Hydrate>
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </QueryClientProvider>
    </ThemeProvider>
);

export default AppProviders;
