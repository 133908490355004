import { useMutation, useQuery, useQueryClient } from 'react-query';

import { CURRENT_USER_QUERY_KEY } from '@api/auth';
import { CommonResponse } from '@api/common/types';
import { useAuthApiClient } from '@api/hooks/useAuthApiClient';

import { FetchError } from '..';
import { Customer, CustomerAddresses, CustomerFile, CustomerFileData, CustomerMutate, VerifyRequest } from './types';

const CUSTOMERS_URL = 'customers';

const CUSTOMER_PROFILE_URL = 'lk/profile';
export const CUSTOMER_PROFILE_KEY = 'profile';

const CUSTOMER_VERIFY_URL = 'lk/verify-requests';
const CUSTOMER_VERIFY_KEY = 'lk/verify-requests';

export const useRegisterCustomer = () => {
    const apiClient = useAuthApiClient();

    return useMutation<CommonResponse<Customer>, FetchError, Partial<CustomerMutate>>(data =>
        apiClient.post(`${CUSTOMERS_URL}/register`, { data })
    );
};

export const useRegisterCustomerByCode = () => {
    const apiClient = useAuthApiClient();

    return useMutation<CommonResponse<Customer>, FetchError, Partial<CustomerMutate>>(data =>
        apiClient.post(`${CUSTOMERS_URL}/register:by-code`, { data })
    );
};

export const useDeleteCustomer = () => {
    const queryClient = useQueryClient();
    const apiClient = useAuthApiClient();

    return useMutation<CommonResponse<null>, FetchError, number | undefined>(
        id => apiClient.delete(`${CUSTOMERS_URL}/${id}:delete`),
        {
            onSuccess: () => queryClient.invalidateQueries(CUSTOMER_PROFILE_KEY),
        }
    );
};

export const useCustomer = (enabled = true, include?: string[]) => {
    const apiClient = useAuthApiClient();

    return useQuery<CommonResponse<Customer>, FetchError>({
        queryKey: CUSTOMER_PROFILE_KEY,
        queryFn: () => apiClient.get(`${CUSTOMER_PROFILE_URL}`, include && { params: { include } }),
        enabled,
    });
};

export const useUpdateCustomer = () => {
    const queryClient = useQueryClient();
    const apiClient = useAuthApiClient();

    return useMutation<CommonResponse<Customer>, FetchError, Partial<CustomerMutate>>(
        ({ ...data }) => apiClient.patch(`${CUSTOMER_PROFILE_URL}:update`, { data }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(CUSTOMER_PROFILE_KEY);
                queryClient.invalidateQueries(CURRENT_USER_QUERY_KEY);
            },
        }
    );
};

export const useRegisterByOrganization = () => {
    const queryClient = useQueryClient();
    const apiClient = useAuthApiClient();

    return useMutation<CommonResponse<Customer>, FetchError, Partial<CustomerMutate>>(
        data => apiClient.post(`${CUSTOMERS_URL}/register-by-organization`, { data }),
        { onSuccess: () => queryClient.invalidateQueries(CUSTOMER_PROFILE_KEY) }
    );
};

export const useDeleteManager = () => {
    const queryClient = useQueryClient();
    const apiClient = useAuthApiClient();

    return useMutation<CommonResponse<Customer>, FetchError, number>(
        id => apiClient.delete(`${CUSTOMERS_URL}/${id}:delete-by-organization`),
        { onSuccess: () => queryClient.invalidateQueries(CUSTOMER_PROFILE_KEY) }
    );
};

export const useDeleteAddress = () => {
    const queryClient = useQueryClient();
    const apiClient = useAuthApiClient();

    return useMutation<CommonResponse<Customer>, FetchError, number>(
        id => apiClient.delete(`${CUSTOMERS_URL}/address/${id}`),
        { onSuccess: () => queryClient.invalidateQueries(CUSTOMER_PROFILE_KEY) }
    );
};

export const useUploadCustomerFile = () => {
    const queryClient = useQueryClient();
    const apiClient = useAuthApiClient();

    return useMutation<CommonResponse<CustomerFile>, FetchError, CustomerFileData>(
        ({ type, file }) => {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('type', String(type));

            return apiClient.post(`${CUSTOMER_PROFILE_URL}/upload-file`, {
                data: formData,
            });
        },
        { onSuccess: () => queryClient.invalidateQueries(CUSTOMER_PROFILE_KEY) }
    );
};

export const useDeleteCustomerFile = () => {
    const queryClient = useQueryClient();
    const apiClient = useAuthApiClient();

    return useMutation<CommonResponse<{} | null>, FetchError, { file_id: number }>(
        data => apiClient.post(`${CUSTOMER_PROFILE_URL}/delete-file`, { data }),
        {
            onSuccess: () => queryClient.invalidateQueries(CUSTOMER_PROFILE_KEY),
        }
    );
};

export const useUploadCustomerFileForVerifing = () => {
    const queryClient = useQueryClient();
    const apiClient = useAuthApiClient();

    return useMutation<CommonResponse<CustomerFile>, FetchError, CustomerFileData>(({ type, file }) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', String(type));

        return apiClient.post(`${CUSTOMER_VERIFY_URL}:upload-file`, {
            data: formData,
        });
    });
};

export const useGetVerifyRequest = (enabled = true) => {
    const apiClient = useAuthApiClient();

    return useQuery<CommonResponse<VerifyRequest>, FetchError>({
        queryKey: CUSTOMER_VERIFY_KEY,
        queryFn: () => apiClient.get(`${CUSTOMER_VERIFY_URL}`),
        enabled,
    });
};

export const useVerifyRequest = () => {
    const queryClient = useQueryClient();
    const apiClient = useAuthApiClient();

    return useMutation<CommonResponse<VerifyRequest>, FetchError, { changes: Partial<CustomerMutate> }>(
        data => apiClient.post(`${CUSTOMER_VERIFY_URL}`, { data }),
        {
            onSuccess: () => queryClient.invalidateQueries(CUSTOMER_VERIFY_KEY),
        }
    );
};

export const useUpdateCustomerAddress = () => {
    const queryClient = useQueryClient();
    const apiClient = useAuthApiClient();

    return useMutation<CommonResponse<Customer>, FetchError, Partial<CustomerAddresses>>(
        ({ id, ...data }) => apiClient.patch(`${CUSTOMERS_URL}/address/${id}`, { data }),
        {
            onSuccess: () => queryClient.invalidateQueries(CUSTOMER_PROFILE_KEY),
        }
    );
};

export const useCustomerVerifyEmail = () => {
    const apiClient = useAuthApiClient();

    return useMutation<CommonResponse<Customer>, FetchError, string>(token =>
        apiClient.post(`${CUSTOMERS_URL}/customers:verify-email`, {
            data: { token },
        })
    );
};
