import { useCallback, useMemo } from 'react';
import { QueryClient } from 'react-query';

import { ProductDetail } from '@api/catalog';
import { getProductDetail } from '@api/catalog/product/api';
import { CommonResponse } from '@api/common/types';
import { useAddingCompare, useDeleteCompare } from '@api/customers/compare';

import { useCompareAndFavoriteContext } from '@context/compare';

import { useAuthApiClient } from '@api/hooks/useAuthApiClient';
import { useGetUserData } from './useGetUserData';

interface CompareHelper {
    product?: ProductDetail;
    offerId?: number;
}

export const useCompareHelper = ({ product, offerId }: CompareHelper) => {
    const { comparedProducts, setComparedProducts, comparedAuthProductsIdsData } = useCompareAndFavoriteContext();

    const isCompared = useMemo(
        () =>
            Boolean(
                comparedProducts.find(item => {
                    return item.offers?.[0].id === offerId; // Всегда берем первый оффер для добавления/удаления из избранного/сравнения
                })
            ),
        [comparedProducts, product]
    );

    const { apiUser } = useGetUserData();

    const { mutateAsync: createCompare } = useAddingCompare();
    const { mutateAsync: deleteCompare } = useDeleteCompare();

    const apiClient = useAuthApiClient();

    const compareProductHandler = useCallback(
        async (id: number, comparedProduct?: ProductDetail) => {
            const newProducts = [...comparedProducts];
            const index = newProducts.findIndex(item => item.id === id);

            const queryClient = new QueryClient();

            const { key: baseProductKey, fetch: baseProductFetch } = getProductDetail(apiClient)({
                id,
                include: 'brand,images,category,files,seller,attributes',
            });

            let apiProduct: CommonResponse<ProductDetail> | null = null;

            if (!comparedProduct?.id && index < 0)
                apiProduct = await queryClient.fetchQuery(baseProductKey, baseProductFetch);

            if (index >= 0) {
                newProducts.splice(index, 1);
                if (apiUser) {
                    setComparedProducts(newProducts);
                    await deleteCompare({
                        id: comparedAuthProductsIdsData.find(item => item.offer_id === id)?.id,
                    });
                }
            } else {
                if (apiProduct?.data) newProducts.push(apiProduct.data);
                if (comparedProduct) newProducts.push(comparedProduct);

                if (apiUser) {
                    await createCompare({
                        offer_id: id,
                    });
                }
            }

            if (!apiUser) {
                setComparedProducts(newProducts);
            }
        },
        [comparedProducts, comparedAuthProductsIdsData, apiUser]
    );

    return { compareProductHandler, isCompared };
};
