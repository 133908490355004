import { useMutation, useQuery, useQueryClient } from 'react-query';

import { CommonResponse, CommonSearchParams } from '@api/common/types';
import { useAuthApiClient } from '@api/hooks/useAuthApiClient';

import { FetchError } from '..';
import { Address, AddressFilter, AddressMutate, Customer } from './types';

const ADDRESS_URL = 'customers/addresses';

type UseAddressParams = CommonSearchParams<AddressFilter> & {
    enabled?: boolean;
};
export const useAddresses = (data: UseAddressParams = { enabled: true }) => {
    const apiClient = useAuthApiClient();

    return useQuery<CommonResponse<Address[]>, FetchError>({
        queryKey: ['addresses', data],
        queryFn: () => apiClient.post(`${ADDRESS_URL}:search`, { data }),
        enabled: data.enabled,
    });
};

export const useCreateAddress = () => {
    const queryClient = useQueryClient();
    const apiClient = useAuthApiClient();

    return useMutation<CommonResponse<Address>, FetchError, AddressMutate>(
        data => apiClient.post(ADDRESS_URL, { data }),
        {
            onSuccess: () => queryClient.invalidateQueries('addresses'),
        }
    );
};

export const useUpdateAddress = () => {
    const apiClient = useAuthApiClient();

    return useMutation<CommonResponse<Customer>, FetchError, Address>(({ id, ...data }) =>
        apiClient.put(`${ADDRESS_URL}/${id}`, { data })
    );
};

export const useSetDefaultAddress = () => {
    const apiClient = useAuthApiClient();

    return useMutation<CommonResponse<null>, FetchError, number>(id =>
        apiClient.post(`${ADDRESS_URL}/${id}:set-as-default`)
    );
};
