import { useCallback, useMemo } from 'react';

import { ProductDetail } from '@api/catalog';
import { useCreateFavorite, useDeleteFavorite } from '@api/customers';

import { useCompareAndFavoriteContext } from '@context/compare';

import { useGetUserData } from './useGetUserData';

interface FavoriteHelperProps {
    offerId?: number;
    setIsAuthPopup: (val: boolean) => void;
}

export const useFavoriteHelper = ({ offerId, setIsAuthPopup }: FavoriteHelperProps) => {
    const { user } = useGetUserData();
    const { favoriteProductsData } = useCompareAndFavoriteContext();

    const isFavorite = useMemo(
        () => Boolean(favoriteProductsData?.find(item => item.offer_id === offerId)),
        [favoriteProductsData]
    );

    const { mutateAsync: createFavorite } = useCreateFavorite();
    const { mutateAsync: deleteFavorite } = useDeleteFavorite();

    const favoriteHelper = useCallback(
        async (id: number) => {
            const isFavoriteLocal = Boolean(favoriteProductsData?.find(item => item.offer_id === id));

            if (user) {
                if (isFavoriteLocal) {
                    deleteFavorite({
                        id: favoriteProductsData.find(item => item.offer_id === id)?.id,
                    });
                } else {
                    createFavorite({
                        offer_id: id,
                    });
                }
            } else {
                setIsAuthPopup(true);
            }
        },
        [user, favoriteProductsData]
    );

    return { favoriteHelper, isFavorite };
};
