import { useMutation, useQuery } from 'react-query';

import { ChangePasswordData, CurrentUser } from '@api/auth/types';
import { CommonResponse, FetchError } from '@api/common/types';
import { useAuthApiClient } from '@api/hooks/useAuthApiClient';

export const AUTH_URL = 'auth';
export const CURRENT_USER_QUERY_KEY = 'current-user';

export const useCurrentUser = (enabled = true) => {
    const apiClient = useAuthApiClient();

    return useQuery<CommonResponse<CurrentUser>, FetchError>({
        queryKey: CURRENT_USER_QUERY_KEY,
        queryFn: () => apiClient.get(`${AUTH_URL}/current-user`),
        enabled,
    });
};

export const useChangePassword = () => {
    const apiClient = useAuthApiClient();

    return useMutation<CommonResponse<null>, FetchError, ChangePasswordData>(data =>
        apiClient.post(`${AUTH_URL}/password-change`, { data })
    );
};

export const useChangePasswordByEmail = () => {
    const apiClient = useAuthApiClient();

    return useMutation<CommonResponse<null>, FetchError, { token: string; password: string }>(data =>
        apiClient.post(`${AUTH_URL}/password-change-public`, { data })
    );
};

export const useGetSmsCode = () => {
    const apiClient = useAuthApiClient();

    return useMutation<CommonResponse<null>, FetchError, { phone: string }>(data =>
        apiClient.post(`communication/sms:request-code`, { data })
    );
};

export const useVerificationCodeByPhone = () => {
    const apiClient = useAuthApiClient();

    return useMutation<CommonResponse<null>, FetchError, { phone: string }>(data =>
        apiClient.post(`${AUTH_URL}/phone:verification-code`, { data })
    );
};

export const useVerificationCodeByEmail = () => {
    const apiClient = useAuthApiClient();

    return useMutation<CommonResponse<null>, FetchError, { email: string }>(data =>
        apiClient.post(`${AUTH_URL}/email:verification-code`, { data })
    );
};

export const useCheckoutByPhone = () => {
    const apiClient = useAuthApiClient();

    return useMutation<CommonResponse<{ found: boolean }>, FetchError, { phone: string }>(data =>
        apiClient.post(`${AUTH_URL}/checkout:by-phone`, { data })
    );
};

export const useCheckoutByEmail = () => {
    const apiClient = useAuthApiClient();

    return useMutation<CommonResponse<{ found: boolean }>, FetchError, { email: string }>(data =>
        apiClient.post(`${AUTH_URL}/checkout:by-email`, { data })
    );
};
