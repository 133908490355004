import { CommonResponse, CommonSearchParams, FetchError } from '@api/common/types';
import { useBaseQuery } from '@api/hooks';

import { useAuthApiClient } from '@api/hooks/useAuthApiClient';
import { getSeoTags } from './api';
import { SeoData, SeoFilter } from './types';

export const useSeoTags = (data: CommonSearchParams<SeoFilter>, enabled = true) => {
    const apiClient = useAuthApiClient();

    return useBaseQuery<CommonSearchParams<Partial<SeoFilter>>, CommonResponse<SeoData>, FetchError>({
        data,
        getMethod: params => getSeoTags(apiClient)(params),
        enabled,
    });
};
