import { useQuery } from 'react-query';

import { CommonResponse, FetchError, FilterResponse, FilterSearchParam } from '@api/common/types';

import { useAuthApiClient } from '@api/hooks/useAuthApiClient';

export const usePointEnumValuesSearch = (data: FilterSearchParam) => {
    const apiClient = useAuthApiClient();

    return useQuery<CommonResponse<FilterResponse>, FetchError>({
        queryKey: ['point-enum-values', data],
        queryFn: () => apiClient.post('/logistic/point-enum-values:search', { data }),
    });
};
