import { CommonSearchParams } from '@api/common/types';
import { APIClient } from '@api/index';

import { PageFilters } from './types/pages';

const API_URL = 'cms/pages';

const QUERY_KEY_PAGES = 'pages';

export const getContentPages =
    (apiClient: APIClient) =>
    (
        data: CommonSearchParams<Partial<PageFilters>> & {
            fields?: { pages: string };
        }
    ) => ({
        key: [QUERY_KEY_PAGES, data.filter],
        fetch: () =>
            apiClient.post(`${API_URL}:search`, {
                data,
            }),
    });
