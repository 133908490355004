import { useMutation, useQuery, useQueryClient } from 'react-query';

import { CommonResponse, CommonSearchParams } from '@api/common/types';
import { useAuthApiClient } from '@api/hooks/useAuthApiClient';

import { FetchError } from '../index';
import {
    ProductDetail,
    ProductFilter,
    ProductOffer,
    ProductZeroStockEmailSubscription,
    ProductZeroStockEmailSubscriptionData,
    ProductZeroStockEmailSubscriptionVerify,
    ProductsFilterItem,
} from './types';
import { SearchRequestData, SearchResultByGroup } from './types/index';

const API_URL = 'catalog/offers';
const API_URL_AUTH = 'catalog/auth-products';

export const QUERY_KEY_OFFERS = 'offers';
export const QUERY_KEY_AUTH_OFFERS = 'auth-offers';
export const QUERY_KEY_OFFER_ZERO_STOCK = 'offer-zero-stock';
export const QUERY_KEY_OFFER = 'offer';

export const productsQueryFn = (data: CommonSearchParams<Partial<ProductFilter>>) => {
    const apiClient = useAuthApiClient();

    return apiClient.post(`${API_URL}:search`, {
        data,
    });
};

export const useProductsSearch = (
    data: CommonSearchParams<Partial<ProductFilter>> & {
        location: {
            lat: number;
            lon: number;
        };
    },
    enabled = true
) => {
    const apiClient = useAuthApiClient();

    return useQuery<CommonResponse<(ProductDetail & ProductOffer)[]>, FetchError>({
        queryKey: [QUERY_KEY_OFFER, data],
        queryFn: () => apiClient.post(`${API_URL}:search`, { data }),
        enabled,
    });
};

export const useOffersSearch = () => {
    const apiClient = useAuthApiClient();

    return useMutation<CommonResponse<SearchResultByGroup>, FetchError, SearchRequestData>(data =>
        apiClient.post(`${API_URL}:suggest`, { data })
    );
};

export const useProductsAuthFilters = (data: CommonSearchParams<Partial<ProductFilter>>, enabled = true) => {
    const apiClient = useAuthApiClient();

    return useQuery<CommonResponse<ProductsFilterItem>, FetchError>({
        queryKey: [QUERY_KEY_AUTH_OFFERS, data],
        queryFn: () =>
            apiClient.post(`${API_URL_AUTH}:facets`, {
                data,
            }),
        enabled,
    });
};

export const useSubscribeZeroStockEmail = () => {
    const queryClient = useQueryClient();
    const apiClient = useAuthApiClient();

    return useMutation<
        CommonResponse<ProductZeroStockEmailSubscription>,
        FetchError,
        ProductZeroStockEmailSubscriptionData
    >(
        delivery => {
            const { id, ...deliveryData } = delivery;

            return apiClient.post(`${API_URL}/${id}:subscribe`, {
                data: deliveryData,
            });
        },
        {
            onSuccess: () => queryClient.invalidateQueries(QUERY_KEY_OFFER_ZERO_STOCK),
        }
    );
};

export const useSubscribeZeroStockEmailVerify = (data: ProductZeroStockEmailSubscriptionVerify, enabled = true) => {
    const apiClient = useAuthApiClient();

    return useQuery<CommonResponse<object>, FetchError>({
        queryKey: [QUERY_KEY_OFFER_ZERO_STOCK, data],
        queryFn: () =>
            apiClient.post(`${API_URL}/verify-subscription`, {
                data,
            }),
        enabled,
    });
};
