import { CSSObject } from '@emotion/core';
import { rgba } from 'emotion-rgba';

import { useTheme } from '@scripts/gds';

export type Link = 'blue' | 'black' | 'grey' | 'secondary';

const getLinkStyles = (color: string | undefined, hoverColor: string | undefined): CSSObject => ({
    fill: 'currentColor',
    color,
    svg: { verticalAlign: 'middle' },
    span: {
        borderBottomColor: rgba(color || '', 0.2),
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
    },
    transition: 'color .2s ease',
    ':hover': {
        color: hoverColor,
        span: { borderBottomColor: rgba(hoverColor || '', 0.2) },
    },
});

export const useLinkCSS = (type: Link = 'blue') => {
    const { colors } = useTheme();

    if (type === 'black') {
        return getLinkStyles(colors?.grey900, colors?.link);
    }

    if (type === 'secondary') {
        return getLinkStyles(colors?.secondary, colors?.primary);
    }

    if (type === 'grey') {
        return getLinkStyles(colors?.grey800, colors?.grey900);
    }

    return getLinkStyles(colors?.link, colors?.primaryHover);
};
