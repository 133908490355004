import { TokenDataProps } from '@context/auth';

import { EMPTY_USER, HttpCode, MILISECONDS_IN_SECOND, TOKEN_TIME_DIFF_SECONDS } from '@scripts/constants';

import { LoginData } from './auth/helpers';
import { CommonResponse, Config, FetchError } from './common/types';

const API_URL = '/api/api-front/';

export class APIClient {
    baseURL: string;

    token: TokenDataProps | undefined;

    static setToken: ((val: TokenDataProps) => void) | undefined;

    readonly authURL = 'auth';

    static checkRequest: Promise<LoginData> | null = null;

    constructor(baseURL: string, token?: TokenDataProps, setToken?: (val: TokenDataProps) => void) {
        this.baseURL = baseURL;
        this.token = token;
        APIClient.setToken = setToken;
    }

    static checkAuthorization(response: Response) {
        if (response.status === HttpCode.UNAUTHORIZED && APIClient.setToken) {
            fetch(`${API_URL}clear`, {
                method: 'POST',
            });
            APIClient.setToken(EMPTY_USER);
        }
    }

    static async returnJSON(response: Response) {
        APIClient.checkAuthorization(response);

        const json: CommonResponse<any> = await response.json();

        if (!response.ok) {
            let errorMessage = 'Request failed';
            let errorCode = '';
            /** we must throw errors to allow react-query catch them in hooks */
            if (json.errors && json.errors.length > 0) {
                errorMessage = json.errors.map(e => e.message).join(` \n`);
                errorCode = [...new Set(json.errors.map(e => e.code))].join(` & `);
            }
            throw new FetchError(errorMessage, response.status, errorCode);
        }

        return json;
    }

    static async returnBlob(response: Response) {
        APIClient.checkAuthorization(response);

        return response.blob();
    }

    protected async unauthorizedClient(
        endpoint: string,
        { data, token, timeout = 10000, headers: customHeaders = {}, params, ...customConfig }: Config = {}
    ) {
        const endpoinWithParams = `${endpoint}${params ? `?${new URLSearchParams(params)}` : ''}`;

        const controller = new AbortController();
        const timer = setTimeout(() => controller.abort(), timeout);

        const config = {
            method: data ? 'POST' : 'GET',

            body: data
                ? typeof window !== 'undefined' && data instanceof FormData
                    ? data
                    : JSON.stringify(data)
                : undefined,
            headers: {
                ...(data &&
                    !(typeof window !== 'undefined' && data instanceof FormData) && {
                        'Content-Type': 'application/json',
                    }),
                ...((!!token || !!this.token?.accessToken) && {
                    Authorization: `Bearer ${token || this.token?.accessToken}`,
                }),
                ...customHeaders,
            },
            ...customConfig,
            signal: controller.signal,
        };
        const response = await fetch(`${this.baseURL}${endpoinWithParams}`, config);
        clearTimeout(timer);

        return response;
    }

    static async refreshToken(): Promise<LoginData> {
        const response = await fetch(`${API_URL}refresh`, {
            method: 'POST',
        }).then(APIClient.returnJSON);

        return response;
    }

    protected async checkToken() {
        let token = '';

        if (typeof window === 'undefined') return token;

        try {
            const timeNow = Math.floor(Date.now() / MILISECONDS_IN_SECOND);

            if (
                this.token?.expiresAt &&
                this.token?.hasRefreshToken &&
                +this.token.expiresAt < timeNow - TOKEN_TIME_DIFF_SECONDS
            ) {
                if (!APIClient.checkRequest) {
                    APIClient.checkRequest = APIClient.refreshToken();
                }
                const result = await APIClient.checkRequest;

                if (result) {
                    if (APIClient.setToken)
                        APIClient.setToken({
                            accessToken: result.data.access_token,
                            hasRefreshToken: Boolean(result.data.refresh_token),
                            expiresAt: result.data.expires_in,
                        });
                    token = result.data.access_token;
                }

                APIClient.checkRequest = null;
            } else if (this.token?.accessToken) {
                token = this.token.accessToken;
            }
        } catch (e) {
            console.error(`Unable to check token: ${e}`);
        }

        return token;
    }

    public async request(endpoint: string, config?: Config) {
        const token = await this.checkToken();

        return this.unauthorizedClient(endpoint, { ...config, token }).then(APIClient.returnJSON);
    }

    public async get(endpoint: string, config?: Omit<Config, 'data'>) {
        return this.request(endpoint, { ...config, method: 'GET' });
    }

    public async post(endpoint: string, config?: Config) {
        return this.request(endpoint, { ...config, method: 'POST' });
    }

    public async patch(endpoint: string, config?: Config) {
        return this.request(endpoint, { ...config, method: 'PATCH' });
    }

    public async put(endpoint: string, config?: Config) {
        return this.request(endpoint, { ...config, method: 'PUT' });
    }

    public async delete(endpoint: string, config?: Config) {
        return this.request(endpoint, { ...config, method: 'DELETE' });
    }

    public async downloadFile(endpoint: string, config?: Config) {
        const token = await this.checkToken();
        const response = await this.unauthorizedClient(endpoint, {
            ...config,
            token,
            method: 'POST',
        });

        if (!response.ok) {
            const errorData = await response.json();
            const errorMessage =
                Array.isArray(errorData?.errors) && errorData.errors.length > 0 && errorData.errors[0]?.message
                    ? errorData.errors[0].message
                    : 'Ошибка загрузки файла';
            throw new Error(errorMessage);
        }

        return APIClient.returnBlob(response);
    }

    public async logOut() {
        return this.request(`logout`);
    }
}

export const apiFront = new APIClient(API_URL);
export const apiAnyClient = new APIClient('https://any-collections.diginetica.net/api/sites/');
export const apiAnyQuery = new APIClient('https://sort.diginetica.net/');

export { FetchError };
