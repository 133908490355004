import { FC, ReactNode, ReactNodeArray, createContext, useCallback, useContext, useRef } from 'react';

export interface BodyScrollLockContextProps {
    disableScroll: () => void;
    enableScroll: () => void;
}

const BodyScrollLockContext = createContext<BodyScrollLockContextProps | null>(null);
BodyScrollLockContext.displayName = 'BodyScrollLockContext';

export const BodyScrollLockProvider: FC<{
    children: ReactNode | ReactNodeArray;
}> = ({ children }) => {
    const refPopups = useRef<number>(0);

    const disableScroll = useCallback(() => {
        refPopups.current += 1;
        if (refPopups.current) {
            document.body.style.overflow = 'hidden';
            document.body.style.height = '100%';
            document.body.style.maxHeight = '100vh';
        }
    }, []);

    const enableScroll = useCallback(() => {
        if (refPopups.current > 0) refPopups.current -= 1;
        if (!refPopups.current) {
            document.body.style.overflow = '';
            document.body.style.height = 'initial';
            document.body.style.maxHeight = 'initial';
        }
    }, []);

    return (
        <BodyScrollLockContext.Provider
            value={{
                disableScroll,
                enableScroll,
            }}
        >
            {children}
        </BodyScrollLockContext.Provider>
    );
};

export const useBodyScrollLock = () => {
    const context = useContext(BodyScrollLockContext);

    if (!context) {
        throw new Error(`Hook useBodyScrollLock must be used within BodyScrollLockProvider`);
    }

    return context;
};
