import dynamic from 'next/dynamic';
import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';

import { SearchResultByGroup } from '@api/catalog';
import { useOffersSearch } from '@api/catalog';

import Form from '@components/controls/Form';

import { Button, colors, scale } from '@scripts/gds';
import { useDebounce, useMedia } from '@scripts/hooks';

import SearchIcon from '@icons/small/search.svg';

const SearchDropdown = dynamic(() => import('@views/catalog/search/components/SearchDropdown'), { ssr: false });

export type SearchProps = {
    onSearch: (search: { categoryId?: number; q: string }) => void;
    setIsCatalogPopup?: () => void;
    placeholder?: string;
    isButtonHidden?: boolean;
    className?: string;
    initialValue?: string;
};

const Input: FC<any> = ({ field, ...props }) => {
    delete props.meta;
    delete props.helpers;

    return (
        <input
            autoComplete="off"
            css={{
                paddingRight: scale(8),
                width: '100%',
                height: '100%',
                border: 'none',
                ':focus': {
                    outline: 'none',
                },
            }}
            {...field}
            {...props}
        />
    );
};

export const Search: FC<SearchProps> = ({ onSearch, isButtonHidden, initialValue, setIsCatalogPopup, ...props }) => {
    const { lgMin, lg } = useMedia();

    const [partialSearch, setPartialSearch] = useState<SearchResultByGroup>();
    const [searchInput, setSearchInput] = useState('');
    const debouncedInput = useDebounce(searchInput, 300);
    const [searchDropdownVisible, setSearchDropdownVisible] = useState<boolean>(false);
    const { mutateAsync: onInputSearch } = useOffersSearch();

    useEffect(() => {
        if (debouncedInput === '' || isButtonHidden) {
            setSearchDropdownVisible(false);

            return;
        }

        onInputSearch({
            filter: {
                query: debouncedInput,
            },
        }).then(({ data: serchResult }) => {
            setSearchDropdownVisible(
                Boolean(
                    Object.values(serchResult).every(group => {
                        return group.length !== 0;
                    })
                )
            );

            setPartialSearch(serchResult);
        });
    }, [debouncedInput, isButtonHidden]);

    const focusHandler = useCallback(() => {
        setIsCatalogPopup && setIsCatalogPopup();
        setSearchDropdownVisible(
            Boolean(
                partialSearch &&
                    Object.values(partialSearch).every(group => {
                        return group.length !== 0;
                    })
            )
        );
    }, [partialSearch, searchDropdownVisible]);

    return (
        <div css={{ position: 'relative' }} id="SearchComponent">
            <Form
                initialValues={{ search: initialValue || '' }}
                onSubmit={vals => {
                    if (vals.search) {
                        onSearch({
                            q: vals.search,
                        });

                        if (isButtonHidden) return;
                    }
                    setSearchDropdownVisible(false);
                }}
                css={{
                    position: 'relative',
                    ...(!isButtonHidden && { marginRight: scale(1) }),
                    marginRight: scale(1),
                    border: `1px solid ${colors.secondaryOnDark}`,
                    height: scale(6),
                    borderRadius: scale(1, false, 6),
                    padding: 2,
                    display: 'flex',
                    alignItems: 'center',
                    [lg]: {
                        paddingLeft: scale(2),
                        marginRight: 0,
                        marginBottom: scale(1),
                    },
                }}
            >
                <>
                    <Form.Field
                        name="search"
                        onInput={(evt: ChangeEvent<HTMLInputElement>) => setSearchInput(evt.target.value)}
                        {...props}
                        css={{
                            height: '100%',
                            paddingLeft: scale(3, true),
                            [lgMin]: { ...(isButtonHidden && { paddingLeft: scale(2) }) },
                        }}
                        onFocus={focusHandler}
                    >
                        <Input />
                    </Form.Field>

                    <Button
                        data-testid="button-search"
                        type="submit"
                        theme="secondary"
                        size="sm"
                        hidden
                        Icon={SearchIcon}
                        css={{
                            width: scale(6),
                            height: scale(5),
                            position: 'absolute',
                            top: 3,
                            right: 3,
                        }}
                    >
                        Поиск
                    </Button>
                </>
            </Form>

            {searchDropdownVisible && (
                <SearchDropdown
                    searchRequest={searchInput}
                    searchData={partialSearch}
                    setVisible={value => setSearchDropdownVisible(value)}
                />
            )}
        </div>
    );
};
