import { useMutation, useQuery, useQueryClient } from 'react-query';

import { CommonResponse, CommonSearchParams } from '@api/common/types';
import { useAuthApiClient } from '@api/hooks/useAuthApiClient';

import { FetchError } from '..';
import { Favorite, FavoriteFilter, FavoriteMutate, MassFavoriteMutate } from './types';

const FAVORITE_URL = 'customers/favorite-offers';
export const FAVORITE_KEY = 'favorites';

export const useFavorites = (data: CommonSearchParams<FavoriteFilter>, enabled = true) => {
    const apiClient = useAuthApiClient();

    return useQuery<CommonResponse<Favorite[]>, FetchError>({
        queryKey: [FAVORITE_KEY, data],
        queryFn: () => apiClient.post(`${FAVORITE_URL}:search`, { data }),
        enabled,
    });
};

export const useCreateFavorite = () => {
    const queryClient = useQueryClient();
    const apiClient = useAuthApiClient();

    return useMutation<CommonResponse<Favorite>, FetchError, FavoriteMutate>(
        data => apiClient.post(FAVORITE_URL, { data }),
        {
            onSuccess: () => queryClient.invalidateQueries(FAVORITE_KEY),
        }
    );
};

export const useDeleteFavorite = () => {
    const queryClient = useQueryClient();
    const apiClient = useAuthApiClient();

    return useMutation<CommonResponse<null>, FetchError, FavoriteMutate>(
        data => apiClient.delete(`${FAVORITE_URL}/${data.id}`, { data }),
        { onSuccess: () => queryClient.invalidateQueries(FAVORITE_KEY) }
    );
};

export const useMassCreateFavorites = () => {
    const queryClient = useQueryClient();
    const apiClient = useAuthApiClient();

    return useMutation<CommonResponse<Favorite>, FetchError, MassFavoriteMutate>(
        data => apiClient.post(`${FAVORITE_URL}:mass-create`, { data }),
        {
            onSuccess: () => queryClient.invalidateQueries(FAVORITE_KEY),
        }
    );
};
