import { useCityStore } from '@store/city';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { CommonResponse } from '@api/common/types';
import { useAuthApiClient } from '@api/hooks/useAuthApiClient';
import { LOGISTIC_CONSOLIDATED_KEY, LOGISTIC_KEY } from '@api/logistic';

import { FetchError } from '..';
import {
    BasketDeliveries,
    BasketProduct,
    BasketProductData,
    BasketServiceData,
    BasketWithDeliveriesData,
} from './types';

export const BASE_URL = 'baskets';
export const BASKET_QUERY_KEY = 'basket';
export const BASKET_DELIVERIES_QUERY_KEY = 'basket-deliveries';

export const useBasketAddProduct = () => {
    const queryClient = useQueryClient();

    const apiClient = useAuthApiClient();

    return useMutation<CommonResponse<null>, FetchError, BasketProductData[]>(
        data => apiClient.post(`${BASE_URL}:set-item`, { data: { items: data } }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(BASKET_QUERY_KEY);
                queryClient.invalidateQueries(BASKET_DELIVERIES_QUERY_KEY);
                queryClient.invalidateQueries(LOGISTIC_KEY);
                queryClient.invalidateQueries(LOGISTIC_CONSOLIDATED_KEY);
            },
        }
    );
};

export const useGetBasket = (enabled = true) => {
    const queryClient = useQueryClient();

    const currentCity = useCityStore(({ currentCity }) => currentCity);

    const apiClient = useAuthApiClient();

    return useQuery<CommonResponse<BasketProduct[]>, FetchError>({
        queryKey: [BASKET_QUERY_KEY],
        queryFn: () =>
            apiClient.post(`${BASE_URL}:current`, {
                data: {
                    include: ['images', 'items.service_types'],
                    customer_region_guid: currentCity.region_guid,
                },
            }),
        enabled: enabled && Boolean(currentCity.region_guid),
    });
};

export const useDeleteBasket = () => {
    const queryClient = useQueryClient();
    const apiClient = useAuthApiClient();

    return useMutation<CommonResponse<null>, FetchError>(() => apiClient.delete(BASE_URL), {
        onSuccess: () => {
            queryClient.invalidateQueries(BASKET_QUERY_KEY);
            queryClient.invalidateQueries(BASKET_DELIVERIES_QUERY_KEY);
            queryClient.invalidateQueries(LOGISTIC_KEY);
            queryClient.invalidateQueries(LOGISTIC_CONSOLIDATED_KEY);
        },
    });
};

export const useGetBasketWithDeliveries = (
    data: BasketWithDeliveriesData,
    region_guid: string = '',
    enabled = true
) => {
    const currentCity = useCityStore(({ currentCity }) => currentCity);
    const apiClient = useAuthApiClient();

    return useQuery<CommonResponse<BasketDeliveries>, FetchError>({
        queryKey: [BASKET_DELIVERIES_QUERY_KEY, data],
        queryFn: () =>
            apiClient.post(`${BASE_URL}:current-with-deliveries`, {
                data: {
                    ...data,
                    include: ['images'],
                    customer_region_guid: region_guid || currentCity.region_guid,
                },
            }),
        enabled: enabled && Boolean(region_guid || currentCity.region_guid),
    });
};

export const useBasketUpdateServices = () => {
    const queryClient = useQueryClient();
    const apiClient = useAuthApiClient();

    return useMutation<CommonResponse<null>, FetchError, BasketServiceData[]>(
        data =>
            apiClient.post(`${BASE_URL}:update-item-services`, {
                data: { offer_service_types: data },
            }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(BASKET_QUERY_KEY);
                queryClient.invalidateQueries(BASKET_DELIVERIES_QUERY_KEY);
            },
        }
    );
};
